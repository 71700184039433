import './App.css';
import Header from './Components/Header/Header';
import Parralax from './Components/Parallax/Parallax';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import GetWorks from './Components/Arts/GetWorks/GetWorks';
import HomePage from './Components/Views/Homepage/HomePage';

function App() {
    return (
        <div className='App'>
            <Header />
            <Parralax />

            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/arts/:path' element={<GetWorks />} exact />
            </Routes>
        </div>
    );
}

export default App;

import React from 'react';
import './about.scss';
import { IoMdCall, IoIosMail } from 'react-icons/io';
import avatar from '../../Assets/icons/avatar.jpg';
import { FB, IN, TWT, MS, WA, YT } from '../../Assets/icons/index';

function About() {
    return (
        <>
            <div className='about' id='omnie'>
                <div className='about__title'>Judyta Gnyp-Nosal</div>
                <div className='about__desc'>
                    <div className='about__desc--image'>
                        <img src={avatar} alt='' />
                    </div>
                    <div className='about__desc--text'>
                        <p>
                            Hi there! My name is Judyta Gnyp-Nosal. I'm a
                            Illustrator and Graphic Designer located in
                            Lubartow, Poland.
                        </p>
                        <div>
                            Education:
                            <ul>
                                <li>
                                    Strzeminski Academy of Art Łódź (ASP Łódź),
                                    Poland | Postgraduate Studies in Graphic
                                    Design
                                </li>
                                <li>
                                    Maria Curie-Sklodowska University in Lublin
                                    (UMCS Lublin), Poland | Artistic Education
                                    in the field of fine arts, specializing in
                                    painting media
                                </li>
                            </ul>
                        </div>
                        <p>
                            Please take a look at my portfolio and if You are
                            interested in cooperation do not hesistate contact
                            me:
                        </p>
                        <div className='about__desc--email'>
                            <a href='mailto://judyta.gnyp@gmail.com'>
                                judyta.gnyp@gmail.com
                            </a>
                        </div>
                        <div className='about__desc--social'>
                            <div className='icon'>
                                <a
                                    href='https://www.facebook.com/ShadowDogDesign/'
                                    target='_blank'
                                >
                                    <img src={FB} alt='facebook' />
                                </a>
                            </div>
                            <div className='icon'>
                                <a
                                    href='https://www.instagram.com/judytagnypnosal_art/'
                                    target='_blank'
                                >
                                    <img src={IN} alt='instagram' />
                                </a>
                            </div>
                            <div className='icon'>
                                <a
                                    href='https://www.youtube.com/channel/UCOi44lwWympGrXpYhTknpeg'
                                    target='_blank'
                                >
                                    <img src={YT} alt='youtube' />
                                </a>
                            </div>
                            <div className='icon'>
                                <a
                                    href='https://api.whatsapp.com/send?phone=667676606'
                                    target='_blank'
                                >
                                    <img src={WA} alt='whatsapp' />
                                </a>
                            </div>
                            <div className='icon'>
                                <a
                                    href='https://m.me/judyta.gnyp'
                                    target='_blank'
                                >
                                    <img src={MS} alt='messenger' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default About;

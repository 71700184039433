import React, { useEffect, useState } from 'react';
import './header.scss';

function Header() {
    const [offSetY, setOffSetY] = useState(0);

    const handleOffset = () => setOffSetY(window.scrollY);

    useEffect(() => {
        window.addEventListener('scroll', handleOffset);

        return () => window.removeEventListener('scroll', handleOffset);
    }, []);

    return (
        <div className={`header ${offSetY >= 300 ? 'absolute' : 'fixed'}`}>
            <nav className='header__nav'>
                <div className='header__nav--link'>
                    <a href='/#portfolio'>Portfolio</a>
                </div>
                <div className='header__nav--link'>
                    <a href='/#omnie'>O mnie</a>
                </div>
            </nav>
        </div>
    );
}

export default Header;

import axios from 'axios';
import React from 'react';
import { useParams } from 'react-router-dom';
import './getworks.scss';
import '../../ModalWork/modalwork.scss';

function withParams(Component) {
    return (props) => <Component {...props} params={useParams()} />;
}

class GetWorks extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            path: this.props.params.path,
            items: [],
            item: [],
            error: null,
            isLoading: false,
            currentItem: null,
            openModal: false,
        };
    }

    changeCurrentItem(id) {
        this.setState({
            openModal: true,
        });

        axios
            .get(`https://apip.shadowdog.eu/works/${id}`)
            .then((res) =>
                this.setState({
                    item: res.data,
                    isLoading: true,
                })
            )
            .catch((err) =>
                this.setState({ error: err.message, isLoading: true })
            );
    }

    componentDidMount() {
        axios
            .get(`https://apip.shadowdog.eu/works?path=${this.state.path}`)
            .then((res) =>
                this.setState({
                    items: res.data,
                    isLoading: true,
                })
            )
            .catch((err) =>
                this.setState({
                    error: err.message,
                    isLoading: true,
                })
            );
    }

    render() {
        const { item, items, error, isLoading } = this.state;
        if (error) return <div>Error: {error}</div>;
        if (!isLoading) return <div>Loading</div>;
        return (
            <>
                <div className='works'>
                    {items.length > 0 ? (
                        items.map((e) => (
                            <div
                                className='works__element'
                                onClick={() => this.changeCurrentItem(e.id)}
                            >
                                <img
                                    src={e.mainImg.map((i) => i.path)}
                                    alt=''
                                    className='works__element--image'
                                />
                            </div>
                        ))
                    ) : (
                        <div className='null'>
                            Nie ma żadnych prac w tej kategorii
                        </div>
                    )}
                </div>

                <div
                    className={`modalWorks ${
                        this.state.openModal
                            ? 'modalWorks--opened'
                            : 'modalWorks--closed'
                    }`}
                >
                    <div
                        className='modalClose'
                        onClick={() => this.setState({ openModal: false })}
                    >
                        X
                    </div>

                    <div className='modalContent'>
                        <div className='modalContent__title'>{item.name}</div>

                        <div className='modalContent__desc'>
                            {item.link && item.link !== 'undefined' ? (
                                <div>
                                    <iframe
                                        title='youtube'
                                        className='video'
                                        src={item.link}
                                        frameborder='0'
                                        allowFullScreen
                                    />
                                </div>
                            ) : null}
                            {item.gallery
                                ? item.gallery.map((image) => (
                                      <img
                                          src={image.path}
                                          alt=''
                                          className=''
                                      />
                                  ))
                                : null}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withParams(GetWorks);

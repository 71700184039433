import React, { Component } from 'react';
import axios from 'axios';
import './getartscategories.scss';
import { Link } from 'react-router-dom';

export default class GetArtsCategories extends Component {
    constructor(props) {
        super(props);

        this.state = {
            elements: [],
            error: null,
            isLoading: true,
        };
    }

    componentDidMount() {
        axios
            .get('https://apip.shadowdog.eu/category')
            .then((res) =>
                this.setState({
                    elements: res.data,
                    isLoading: false,
                })
            )
            .catch((error) =>
                this.setState({
                    error: error.message,
                    isLoading: false,
                })
            );
    }

    render() {
        const { elements, error, isLoading } = this.state;

        if (error) return <div>Error</div>;
        else if (isLoading) return <div>Loading</div>;
        else
            return (
                <>
                    {elements.map((e) => (
                        <a href={`arts/${e.path}`}>
                            <div className='categoryWrapper' key={e.id}>
                                <img
                                    src={e.images[0].path}
                                    alt=''
                                    className='categoryWrapper--img'
                                />
                                <div className='categoryName'>{e.name}</div>
                            </div>
                        </a>
                    ))}
                </>
            );
    }
}

import React from 'react';
import About from '../../About/About';
import Arts from '../../Arts/Arts';
import Coop from '../../Coop/Coop';

function HomePage() {
    return (
        <>
            <Arts />

            <About />

            <Coop />
        </>
    );
}

export default HomePage;

import React from 'react';
import GetArtsCategories from './GetArtsCategories/GetArtsCategories';
import './arts.scss';

function Arts() {
    return (
        <div id='portfolio'>
            <div className='categoryTitle'>Portfolio</div>

            <div className='categoryContainer'>
                <GetArtsCategories />
            </div>
        </div>
    );
}

export default Arts;

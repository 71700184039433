import React from 'react';
import Kokito from '../../Assets/logos/kokito.png';
import Breeder from '../../Assets/logos/4breeder.png';
import './coop.scss';

const data = [
    {
        name: 'Kokito',
        url: 'https://kookitodesign.com',
        imgUrl: Kokito,
    },
    {
        name: '4Breeder',
        url: 'https://4breeder.pl',
        imgUrl: Breeder,
    },
];

function Coop() {
    return (
        <>
            <div className='coop'>
                <div className='coop__title'>Zaufali mi</div>
                <div className='coop__elements'>
                    {data.map((e) => (
                        <a href={e.url} key={e.name} target='_blank'>
                            <img src={e.imgUrl} alt={e.name} />
                        </a>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Coop;

import React, { useEffect, useState } from 'react';
import './parallax.scss';

//images
import zero from '../../Assets/parallax/0.jpg';
import one from '../../Assets/parallax/1.png';
import two from '../../Assets/parallax/2.png';
import three from '../../Assets/parallax/3.png';
import four from '../../Assets/parallax/4.png';
import five from '../../Assets/parallax/5.png';
import six from '../../Assets/parallax/6.png';
import seven from '../../Assets/parallax/7.png';
import eight from '../../Assets/parallax/8.png';
import nine from '../../Assets/parallax/9.png';
import { ARROW } from '../../Assets/icons';

function Parralax() {
    const [offSetY, setOffSetY] = useState(0);

    const handleOffset = () => setOffSetY(window.scrollY);

    useEffect(() => {
        window.addEventListener('scroll', handleOffset);

        return () => window.removeEventListener('scroll', handleOffset);
    }, []);

    return (
        <div className='parallax__wrapper'>
            <img
                src={zero}
                alt=''
                className='p-zero'
                style={{ transform: `translateY(${offSetY * 0.0}px)` }}
            />
            <img
                src={one}
                alt=''
                className='p-one'
                style={{ transform: `translateY(${offSetY * 0.8}px)` }}
            />
            <img
                src={two}
                alt=''
                className='p-two'
                style={{ transform: `translateY(${offSetY * 0.7}px)` }}
            />
            <img
                src={three}
                alt=''
                className='p-three'
                style={{ transform: `translateY(${offSetY * 0.5}px)` }}
            />
            <img
                src={four}
                alt=''
                className='p-four'
                style={{ transform: `translateY(${offSetY * 0.5}px)` }}
            />
            <img
                src={five}
                alt=''
                className='p-five'
                style={{ transform: `translateY(${offSetY * 0.4}px)` }}
            />
            <img
                src={six}
                alt=''
                className='p-six'
                style={{ transform: `translateY(${offSetY * 0.3}px)` }}
            />
            <img
                src={seven}
                alt=''
                className='p-seven'
                style={{ transform: `translateY(${offSetY * 0.3}px)` }}
            />
            <img
                src={eight}
                alt=''
                className='p-eight'
                style={{ transform: `translateY(${offSetY * 0.2}px)` }}
            />
            <img
                src={nine}
                alt=''
                className='p-nine'
                style={{ transform: `translateY(${offSetY * 0}px)` }}
            />

            {offSetY > 500 ? null : (
                <div className='scroll'>
                    <img src={ARROW} alt='scroll down' />
                </div>
            )}
        </div>
    );
}

export default Parralax;
